<template>
  <div>
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">

           <el-form-item label="日期">
            <el-date-picker
              v-model="search.start_time"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <el-date-picker
              v-model="search.end_time"
              type="date"
              class="w130"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="门店/门诊" v-if="!userInfo.shop_id">
            <el-select
              v-model="search.shop_id"
              clearable
              remote
              filterable
              :remote-method="remoteMethod"
              placeholder="请输入"
              class="w84"
            >
              <el-option
                v-for="item in options.shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="护理师">
            <el-input
              v-model="search.person_name"
              maxlength="30"
              class="w84"
              placeholder="请输入"
              clearable
            />
          </el-form-item>
          <el-form-item label="客户">
            <el-input
              v-model="search.customer_name"
              maxlength="30"
              class="w84"
              placeholder="请输入"
              clearable
            />
          </el-form-item>

        </el-form>
      </el-col>
      <el-col :span="2" class="btnBox">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        <el-button type="primary"  size="mini"  :loading="exportS" @click="exportExcel">导出数据</el-button>
      </el-col>
    </el-row>

      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="门店" prop="shop_name" />
            <ElTableColumn label="护理师" prop="person_name" />
            <ElTableColumn label="客户姓名" prop="customer_name" />
            <ElTableColumn label="手机号" prop="telephone" />
            <ElTableColumn label="期初余额" prop="qichuyue" />
            <ElTableColumn label="本月收款金额" prop="benyueshoukuan" />
            <ElTableColumn label="本月消耗" prop="benyuechuku" />
            <ElTableColumn label="疤痕本金" prop="benjin_bahen" />
            <ElTableColumn label="疤痕赠金" prop="zengjin_bahen" />
            <ElTableColumn label="疤痕店转" prop="dianzhuan_bahen" />
            <ElTableColumn label="项目本金" prop="benjin_xiangmu" />
            <ElTableColumn label="项目赠金" prop="zengjin_xiangmu" />
            <ElTableColumn label="项目店转" prop="dianzhuan_xiangmu" />
            <ElTableColumn label="青春痘本金" prop="benjin_qingchundou" />
            <ElTableColumn label="青春痘赠金" prop="zengjin_qingchundou" />
            <ElTableColumn label="青春痘店转" prop="dianzhuan_qingchundou" />
            <ElTableColumn label="护肤品本金" prop="benjin_hufupin" />
            <ElTableColumn label="护肤品赠金" prop="zengjin_hufupin" />
            <ElTableColumn label="护肤品店转" prop="dianzhuan_hufupin" />
            <ElTableColumn label="余额合计" prop="yueheji" />
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>

  </div>
</template>

<script>
import { getLastDay } from '@/utils';
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import { getZyCustomer } from "@/api/statistics";
import { getShopList } from "@/api/shop";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  start_time = "";
  end_time = "";
  person_name="";
  customer_name="";
}
class Options {
  shop_id = [];
}
export default {
  name: "OrderList",
  components: {
    pagination
  },
  data() {
    return {
      search: new Search(), // 检索条件
      options: new Options(),
      page: new Page(), // 分页
      list: [], // 数据展示
      idList: [], // 导出数据
      exportS: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if(month.toString().length == 1){
      month = '0'+ month
    }
    let day = getLastDay(year,month)
    if(day.toString().length == 1){
      day = '0'+ day
    }
    this.search.start_time = year+'-'+ month + '-01'
    this.search.end_time = year+'-'+ month + '-' + day
    this.getList('search');
  },
  methods: {

    getList(option) {
      if (option === "search") this.page = new Page();
      getZyCustomer({  ...this.search, ...this.page,export:0 }).then(res => {
        this.list = res.data.list;
        this.page.total = res.data.dataCount;
      });
    },

    // 搜索门店
    remoteMethod(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shop_id = res.list;
      });
    },

    exportExcel() {
      this.exportS = true
      getZyCustomer({ ...this.search, ...this.page,export:1}).then(res => {
        window.open(res.data.url);
        this.exportS = false
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.btnBox{
  display: flex;
  justify-content: flex-end;
  width: 60%;
  margin-bottom: 10px;
  .el-button{
    height: 28px;
  }
}
.bgW{
  display: flex;
}
</style>
